import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Alert, Box, LinearProgress } from '@mui/material';
// hooks
import useSettings from 'minimal-ui/hooks/useSettings';
import useResponsive from 'minimal-ui/hooks/useResponsive';
import useCollapseDrawer from 'minimal-ui/hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from 'minimal-ui/config';
//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import { useRealmApp } from 'shared-web/components/RealmApp';
import { UserCustomDataCollections, UserTypes } from '@mymeeinc/types/user';
import watchOne from 'shared-web/hooks/watch/watchOne';
import { MemberCustomDatum } from '@mymeeinc/types/customData';
import { useShowLoader } from 'shared-web/hooks/util-hooks';
import { MotionContainer, varFade } from 'minimal-ui/components/animate';
import { m } from 'framer-motion';
import { Container } from '@mui/material';
import { useUsers } from '../../hooks/users/useUsers';
import { useSmartFetch } from '../../hooks/useSmartFetch';
import _ from 'lodash';

// ----------------------------------------------------------------------

type MainStyleProps = {
  collapseClick: boolean;
};

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function LoggedInLayout({ version }: { version: string }) {
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const { projectName, appUserType, themeLayout } = useSettings();
  const isDesktop = useResponsive('up', 'lg');
  const [open, setOpen] = useState(false);
  const { realmApp, user, loadingMapState, refreshCustomData } = useRealmApp();

  const { invalidateCache } = useSmartFetch();
  watchOne({
    callerRef: 'AppMain',
    collectionName: UserCustomDataCollections[appUserType],
    config: {
      setter: async (data: MemberCustomDatum) => {
        console.error('REFRESH CUSTOM DATA VIA WATCH');
        console.error({ newCustomData: data });
        setTimeout(refreshCustomData, 250);
        // setUser(data);
      },
      cacheInvalidator: () => {
        console.warn('invalidate global cache');
        invalidateCache();
      },
    },
  });
  const showLoader = useShowLoader(loadingMapState, 100);
  const { editUser } = useUsers({
    userType: UserTypes.MEMBER,
    callerRef: 'App()',
    noAutoFetch: true,
  });

  useEffect(() => {
    setTimeout(() => {
      try {
        const [first_name, last_name] = [
          realmApp.storage.get('first_name'),
          realmApp.storage.get('last_name'),
        ];
        if (first_name && !!user.account_created_at) {
          console.log({ first_name, last_name, user });
          console.warn('pushing stored first and last name...');
          editUser(user.user_id, {
            ...user,
            first_name,
            last_name,
          } as any)
            .then((resultEditUser) => {
              console.log({ resultEditUser });
              realmApp.storage.remove('first_name');
              realmApp.storage.remove('last_name');
            })
            .catch((e) => console.error(e));
        }
      } catch (e: any) {
        console.warn(e);
      }
    }, 500);
  });

  // useEffect(() => {
  //   console.log({ loadingMapState });
  // }, [isLoading, loadingMapState]);

  const map = {
    aegean: 1,
    aegean2: 2,
    aegean3: 3,
  };
  const devServerNo = map[import.meta.env.VITE_DB_NAME as keyof typeof map];

  const verticalLayout = themeLayout === 'vertical';
  const modeAlert = (
    <>
      {import.meta.env.MODE === 'staging' && (
        <Alert severity="info" style={{ marginTop: '-15px', marginBottom: '20px' }}>
          You are on staging server. {projectName} v{version}. {import.meta.env.VITE_GITHUB_SHA}
          {import.meta.env.VITE_GITHUB_REF_NAME && '@' + import.meta.env.VITE_GITHUB_REF_NAME}
        </Alert>
      )}
      {import.meta.env.MODE === 'development' && (
        <Alert severity="warning" style={{ marginTop: '-15px', marginBottom: '20px' }}>
          You are on development server #{devServerNo}. {projectName} v{version}.{' '}
          {import.meta.env.VITE_GITHUB_SHA}
          {import.meta.env.VITE_GITHUB_REF_NAME && '@' + import.meta.env.VITE_GITHUB_REF_NAME}
        </Alert>
      )}
    </>
  );
  if (verticalLayout) {
    return (
      <>
        <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />

        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          {modeAlert}
          <Outlet />
        </Box>
      </>
    );
  }

  const loggedInLoading = Object.entries(loadingMapState).length;
  // const loading = Boolean(showLoader || loggedInLoading);
  // if (!loading) {
  //   console.warn({ loading, showLoader, loggedInLoading });
  // } else {
  //   console.log({ loading, showLoader, loggedInLoading });
  // }
  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle collapseClick={collapseClick}>
        {modeAlert}
        {showLoader || loggedInLoading ? (
          <LinearProgress value={5} valueBuffer={15} />
        ) : (
          <Container component={MotionContainer}>
            <m.div
              variants={
                varFade({
                  distance: 120,
                  durationIn: 1,
                  durationOut: 0.24,
                  easeIn: 'easeInOut',
                }).in
              }
            >
              <Outlet />
            </m.div>
          </Container>
        )}
      </MainStyle>
    </Box>
  );
}
