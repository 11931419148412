// routes
import { PATH_LOGGED_IN } from './routes/paths';
// components
import SvgIconStyle from 'minimal-ui/components/SvgIconStyle';
import Label from 'minimal-ui/components/Label';
import { ConversationList } from 'shared-web/hooks/useChat';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
};

const navConfig = (conversations: ConversationList) => {
  const unreadConversations = Object.entries(conversations.byId).filter(
    ([, convo]) => convo.unreadCount > 0
  ).length;
  const navConfig = [
    {
      subheader: 'Member Area',
      items: [
        {
          title: 'Dashboard',
          path: PATH_LOGGED_IN.dashboard,
          icon: ICONS.dashboard,
          // info: <Label color="error">+1</Label>,
        },
        {
          title: 'My Symptoms',
          path: PATH_LOGGED_IN.mySymptoms,
          icon: ICONS.analytics,
          // info: <Label color="error">+1</Label>,
        },
        {
          title: 'My Coach',
          path: PATH_LOGGED_IN.myCoach,
          icon: ICONS.user,
          // info: unreadConversations > 0 ? <Label color="error">{unreadConversations}</Label> : null,
        },
        {
          title: 'My Sessions',
          path: PATH_LOGGED_IN.mySessions,
          icon: ICONS.booking,
          // info: <Label color="error">+1</Label>,
        },
        // {
        //   title: 'My Profile',
        //   path: PATH_LOGGED_IN.myProfile,
        //   icon: ICONS.dashboard,
        //   // info: <Label color="error">+1</Label>,
        // },
      ],
    },
    {
      subheader: 'app',
      items: [
        {
          title: 'chat',
          path: PATH_LOGGED_IN.chat.root,
          icon: ICONS.chat,
          info: unreadConversations > 0 ? <Label color="error">{unreadConversations}</Label> : null,
        },
        // {
        //   title: 'calendar',
        //   path: PATH_LOGGED_IN.calendar,
        //   icon: ICONS.calendar,
        //   info: <Label color="warning">+1</Label>,
        // },
        // { title: 'Todo', path: PATH_LOGGED_IN.kanban, icon: ICONS.kanban },
      ],
    },
  ];
  return navConfig;
};

export default navConfig;
