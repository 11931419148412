import { useState, useEffect } from 'react';

export function useTimeout(fn: any, ms: number) {
  useEffect(() => {
    const timeout = setTimeout(() => {
      fn();
    }, ms);
    return () => clearTimeout(timeout);
  }, [fn, ms]);
}

export function useShowLoader(loadingMapState: { [key: string]: boolean }, delayMs: number) {
  const [showLoader, setShowLoader] = useState(false);
  useTimeout(() => {
    if (Object.entries(loadingMapState).length === 0) {
      setShowLoader(false);
    }
  }, delayMs);
  useEffect(() => {
    if (Object.entries(loadingMapState).length > 0) {
      setShowLoader(true);
    }
  }, [loadingMapState]);
  return showLoader;
}
