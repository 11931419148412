// ----------------------------------------------------------------------
import { MemberCustomDatum, CoachCustomDatum, AdminCustomDatum } from './customData';

export type UserCustomDataEdit = Omit<UserCustomDataUnion, '_id'>;
export type UserCustomData = CoachCustomDatum | MemberCustomDatum | AdminCustomDatum;
export type UserCustomDataUnion = CoachCustomDatum & MemberCustomDatum & AdminCustomDatum;
export type UserRealmContextData = UserCustomDataUnion & { user_id: string; displayName: string };

export enum UserTypes {
  ADMIN = 'admin',
  COACH = 'coach',
  MEMBER = 'member',
}

export enum UserCustomDataCollections {
  'admin' = 'admin_custom_data',
  'coach' = 'coach_custom_data',
  'member' = 'member_custom_data',
}
export enum UserCustomDataTitles {
  'admin' = 'adminCustomData',
  'coach' = 'coachCustomData',
  'member' = 'memberCustomData',
}
