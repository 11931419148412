import { useState, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// components
import LoadingScreen from 'minimal-ui/components/LoadingScreen';
import { useRealmApp } from 'shared-web/components/RealmApp';
import { useNavConfigContext } from 'minimal-ui/contexts/NavConfigContext';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { user, isLoading } = useRealmApp();
  const { PATH_GUEST } = useNavConfigContext();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
  if (isLoading) {
    return <LoadingScreen />;
  }

  // console.log({ user, isLoading, realmUser: realmApp.currentUser, pathname, requestedLocation });
  if (!user) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    console.log('navigating to login page.');
    return <Navigate to={PATH_GUEST.login} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return children as JSX.Element;
}
