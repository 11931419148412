import { useWatch } from '../useWatch';
import {
  addValueAtIndex,
  getDocumentIndex,
  removeValueAtIndex,
  replaceValueAtIndex,
  stringifyObjectIds,
  updateValueAtIndex,
} from '../../utils';
import { Dispatch, SetStateAction } from 'react';

// export type SetterInside<T> = (a: Array<T>) => void;
export type SetterInside<T> = (a: T) => void;
export type Setter<T> = (a: SetterInside<T>) => void;
export type WatchConfig = {
  setter: Dispatch<SetStateAction<any>> | Setter<any>;
  injector?: (a: any) => any;
  cacheInvalidator: (change?: any) => void;
  throttle?: number;
};
export type WatchInput = {
  callerRef: string;
  collectionName: string;
  config: WatchConfig;
};
export default function watchMany({
  callerRef,
  collectionName,
  config: { setter, injector = (a) => a, cacheInvalidator },
}: WatchInput) {
  return useWatch(callerRef, collectionName, 'watchMany', {
    onInsert: (change) => {
      cacheInvalidator();
      setter((prevList) => {
        const idx = getDocumentIndex(prevList, change.fullDocument) ?? prevList.length;
        if (idx === prevList.length) {
          return injector(addValueAtIndex(prevList, idx, stringifyObjectIds(change.fullDocument)));
        } else {
          return prevList;
        }
      });
    },
    onUpdate: (change) => {
      cacheInvalidator();
      setter((prevList) => {
        if (!prevList.length) {
          console.warn('onUpdate dropped as prevList empty');
          return [];
        }
        const idx = getDocumentIndex(prevList, change.fullDocument);
        return injector(
          updateValueAtIndex(prevList, idx, () => {
            return stringifyObjectIds(change.fullDocument);
          })
        );
      });
    },
    onReplace: (change) => {
      cacheInvalidator();
      setter((prevList) => {
        if (!prevList.length) {
          console.warn('onReplace dropped as prevList empty');
          return [];
        }
        const idx = getDocumentIndex(prevList, change.fullDocument);
        return injector(
          replaceValueAtIndex(prevList, idx, stringifyObjectIds(change.fullDocument))
        );
      });
    },
    onDelete: (change) => {
      cacheInvalidator();
      setter((prevList) => {
        if (!prevList.length) {
          console.warn('onDelete dropped as prevList empty');
          return [];
        }
        const idx = getDocumentIndex(prevList, {
          _id: change.documentKey._id,
        });
        if (idx >= 0) {
          return injector(removeValueAtIndex(prevList, idx));
        } else {
          return prevList;
        }
      });
    },
  });
}
