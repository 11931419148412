// import React from 'react';
import { gql } from '@apollo/client';
import { getUserIdFieldName, useRealmApp } from 'shared-web/components/RealmApp';
import { useState } from 'react';
import { useApolloClient } from 'shared-web/hooks/useApolloClient';
import {
  UserCustomData,
  UserCustomDataCollections,
  UserCustomDataEdit,
  UserCustomDataTitles,
  UserCustomDataUnion,
  UserTypes,
} from '@mymeeinc/types/user';
import useSettings from 'minimal-ui/hooks/useSettings';
import watchMany from '../watch/watchMany';
import { UseUserEditable } from './queries/types';
import queries from './queries';
import { AssignCoachFn, userTypeToCustomData } from '@mymeeinc/types/customData';
import { gqlMutationInputFields, gqlMutationSetFields } from '../../utils';
import { SmartFetchCacheGetter, useSmartFetch } from '../useSmartFetch';
import uuidv4 from 'minimal-ui/utils/uuidv4';

type UseUsersHookInput = {
  userType: UserTypes;
  userId?: string;
  callerRef: string;
  noAutoFetch?: boolean;
};
const subFieldInjector = (field: string | Array<any>) => {
  console.log({ field });
  if (Array.isArray(field)) {
    const [fieldName, subfields] = field;
    console.log({ fieldName, subfields });
    return `${fieldName} {
                      ${subfields.map((subfield: any) => subFieldInjector(subfield)).join('\n')}
                  }`;
  }
  return field;
};
export type EditUserMethod = (userId: string, formData: UserCustomDataEdit) => Promise<void>;

export const useMembers = (callerRef: string) =>
  useUsers({
    userType: UserTypes.MEMBER,
    callerRef: `${callerRef}:${uuidv4()}`,
  });
export const useCoaches = (callerRef: string, noAutoFetch: boolean = false) =>
  useUsers({
    userType: UserTypes.COACH,
    callerRef: `${callerRef}:${uuidv4()}"`,
    noAutoFetch,
  });
export const useAdmins = (callerRef: string) =>
  useUsers({
    userType: UserTypes.ADMIN,
    callerRef: `${callerRef}:${uuidv4()}`,
  });

export function useUsers({ userType, callerRef, noAutoFetch = false }: UseUsersHookInput): {
  users: UserCustomDataUnion[];
  editUser: EditUserMethod;
  assignCoach: AssignCoachFn;
  getCache: SmartFetchCacheGetter;
  isLoading: boolean;
} {
  // Get a graphql client and set up a list of user in state
  const { setIsLoading: _setIsLoading } = useRealmApp();
  const { project } = useSettings();
  const graphql = useApolloClient(true);
  const [isLoadingInternal, setIsLoadingInternal] = useState<boolean>(true);
  const [users, setUsers] = useState<UserCustomData[]>([]);
  const queryInfos: UseUserEditable = (queries[project] as any)[userType];
  const componentIdentifier = ['useUsers', userType].join(':');
  const setIsLoading = (identifier: string) => (isLoading: boolean) => {
    setIsLoadingInternal(isLoading);
    return _setIsLoading(identifier)(isLoading);
  };

  const fetchAllUsers = async <UserCustomData>(): Promise<Array<UserCustomData>> => {
    const setLoading = setIsLoading(componentIdentifier + ':fetchAllUsers');
    try {
      setLoading(true);
      const actualQuery = queryInfos.queryWithId;
      const query = gql`
        query ${UserCustomDataTitles[userType]} {
          ${UserCustomDataTitles[userType]} {
            ${actualQuery.readFields.map(subFieldInjector).join('\n')}
          }
        }
      `;
      const { data } = await graphql.query({ query, fetchPolicy: 'no-cache' });
      const users = data[UserCustomDataTitles[userType]];
      return users;
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
    return [];
  };

  const { getCache, invalidateCache } = useSmartFetch(componentIdentifier, {
    noAutoFetch,
    set: (customDatas: UserCustomData[]) => {
      setUsers(customDatas);
      setIsLoadingInternal(false);
    },
    fetcher: fetchAllUsers,
    watcher: watchMany,
    injector: (users: UserCustomDataUnion[]): UserCustomDataUnion[] => {
      return users.map((content) => {
        return {
          __typename: userTypeToCustomData(userType),
          ...content,
        } as UserCustomDataUnion;
      });
    },
    collectionName: UserCustomDataCollections[userType],
    key: 'all',
    callerRef,
  });

  const editUser = async (user_id: string, user: UserCustomDataEdit) => {
    const setLoading = setIsLoading(componentIdentifier + `:editUser:${user_id}`);
    try {
      console.log({
        editUser: { user_id, user },
      });
      setLoading(true);

      const mutationResult = await graphql.mutate({
        mutation: gql`
          mutation ${queryInfos.editMutation.name}(
          ${gqlMutationInputFields(queryInfos.editMutation.inputFields)}
          ) {
            ${queryInfos.editMutation.name}(
              query: ${queryInfos.editMutation.query}
              set: {
                ${gqlMutationSetFields(queryInfos.editMutation.setFields)}
              }
            ) {
                ${queryInfos.queryWithId.readFields.map(subFieldInjector).join('\n')}
            }
          }
        `,
        variables: {
          [getUserIdFieldName(userType)]: user_id,
          ...user,
        },
      });
      invalidateCache();

      console.log({ mutationResult });
    } catch (err: any) {
      if (err.message.match(/^Duplicate key error/)) {
        console.warn(
          `The following error means that we tried to insert a todo multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
        );
      }
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const assignCoach = async (coach_id: string, member_id: string) => {
    const setLoading = setIsLoading(componentIdentifier + `:assignCoach:${coach_id}:${member_id}`);
    try {
      setLoading(true);
      const assignCoachResult = await graphql.mutate({
        mutation: gql`
          mutation assignCoach($coach_id: String!, $member_id: String!) {
            assignCoach(input: { coach_id: $coach_id, member_id: $member_id }) {
              coach_id
              member_id
              success
            }
          }
        `,
        variables: { coach_id, member_id },
      });
      console.log({ assignCoachResult });
      invalidateCache();
    } catch (err: any) {
      if (err.message.match(/^Duplicate key error/)) {
        console.warn(
          `The following error means that we tried to insert a todo multiple times (i.e. an existing todo has the same _id). In this app we just catch the error and move on. In your app, you might want to debounce the save input or implement an additional loading state to avoid sending the request in the first place.`
        );
      }
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return {
    users: users as UserCustomDataUnion[],
    isLoading: isLoadingInternal,
    editUser,
    assignCoach,
    getCache,
  };
}
