import { ReadFields, WriteFields } from './types';
import {
  member_CoachReadFields,
  member_AdminReadFields,
  member_MemberReadFields,
  member_MemberWriteInputFields,
  member_MemberWriteSetFields,
} from './member';

export const coach_MemberReadFields: ReadFields = [...member_MemberReadFields, 'is_enabled'];
export const coach_MemberWriteSetFields: WriteFields = [...member_MemberWriteSetFields];
export const coach_MemberWriteInputFields: WriteFields = [...member_MemberWriteInputFields];

export const coach_AdminReadFields: ReadFields = [...member_AdminReadFields];
export const coach_CoachReadFields: ReadFields = [
  ...member_CoachReadFields,
  'acuity_access_token',
  'birthdate',
  'gender',
  'account_created_at',
  'assigned_member_count',
  'email',
  'phone_number',
  'address',
  'country',
  'state',
  'city',
  'zip_code',
];

export const coach_CoachWriteSetFields: WriteFields = [
  ['first_name', 'String!'],
  ['last_name', 'String!'],
  ['phone_number', 'String!'],
  ['address', 'String!'],
  ['country', 'String!'],
  ['state', 'String!'],
  ['birthdate', 'DateTime'],
  ['gender', 'String'],
  ['city', 'String!'],
  ['zip_code', 'String!'],
  ['acuity_access_token', 'String'],
];
export const coach_CoachWriteInputFields: WriteFields = [
  ['coach_id', 'String!'],
  ...coach_CoachWriteSetFields,
];
