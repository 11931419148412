import { ReadFields, WriteFields } from './types';
import {
  coach_AdminReadFields,
  coach_CoachReadFields,
  coach_CoachWriteInputFields,
  coach_CoachWriteSetFields,
  coach_MemberReadFields,
  coach_MemberWriteSetFields,
} from './coach';

export const admin_CoachReadFields: ReadFields = [...coach_CoachReadFields, 'is_enabled'];
export const admin_CoachWriteInputFields: WriteFields = [
  ...coach_CoachWriteInputFields,
  ['is_enabled', 'Boolean!'],
];
export const admin_CoachWriteSetFields: WriteFields = [
  ...coach_CoachWriteSetFields,
  ['is_enabled', 'Boolean!'],
];
export const admin_AdminReadFields: ReadFields = [
  ...coach_AdminReadFields,
  'email',
  'is_enabled',
  'account_created_at',
];
export const admin_AdminWriteSetFields: WriteFields = [
  ['first_name', 'String!'],
  ['last_name', 'String!'],
  ['is_enabled', 'Boolean!'],
];
export const admin_AdminWriteInputFields: WriteFields = [
  ['admin_id', 'String!'],
  ...admin_AdminWriteSetFields,
];
export const admin_MemberReadFields: ReadFields = [...coach_MemberReadFields];

export const admin_MemberWriteSetFields: WriteFields = [
  ...coach_MemberWriteSetFields,
  ['subscription_start_date', 'DateTime'],
  ['subscription_end_date', 'DateTime'],
  ['is_enabled', 'Boolean!'],
];
export const admin_MemberWriteInputFields: WriteFields = [
  ['member_id', 'String!'],
  ...admin_MemberWriteSetFields,
];
