import { ReadFields, WriteFields } from './types';

export const member_MemberReadFields: ReadFields = [
  '_id',
  'member_id',
  'coach_id',
  'first_name',
  'last_name',
  'picture',
  'email',
  'phone_number',
  'address',
  'country',
  'state',
  'city',
  'zip_code',
  'birthdate',
  'gender',
  'has_diagnosed',
  'nickname',
  'account_created_at',
  'subscription_start_date',
  'subscription_end_date',
  ['tags_diagnosis', ['diagnosis_id', 'alias']],
  ['tags_food', ['food_id', 'alias']],
  ['tags_symptom', ['symptom_id', 'alias']],
  ['tags_user_symptom', ['symptom_id', 'alias']],
  [
    'tags_user_diagnosis',
    ['diagnosis_id', 'alias', 'date', ['diagnosis_symptoms', ['symptom_id', 'alias']]],
  ],
];

export const member_CoachReadFields: ReadFields = [
  '_id',
  'coach_id',
  'first_name',
  'last_name',
  'picture',
];
export const member_AdminReadFields: ReadFields = [
  '_id',
  'admin_id',
  'first_name',
  'last_name',
  'picture',
];
export const member_MemberWriteSetFields: WriteFields = [
  ['nickname', 'String!'],
  ['first_name', 'String!'],
  ['last_name', 'String!'],
  ['phone_number', 'String!'],
  ['address', 'String!'],
  ['country', 'String!'],
  ['state', 'String!'],
  ['city', 'String!'],
  ['zip_code', 'String!'],
  ['gender', 'String'],
  ['birthdate', 'DateTime'],
];
export const member_MemberWriteInputFields: WriteFields = [
  ['member_id', 'String!'],
  ...member_MemberWriteSetFields,
];
