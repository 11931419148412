import { ElementType, Suspense } from 'react';
import { useRealmApp } from '../components/RealmApp';
import LoadingScreen from 'minimal-ui/components/LoadingScreen';

export const Loadable = (Component: ElementType) => (props: any) => {
  const { realmApp } = useRealmApp();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={!!realmApp.currentUser} />}>
      <Component {...props} />
    </Suspense>
  );
};
