// hooks
import { useRealmApp } from 'shared-web/components/RealmApp';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar, { Props as AvatarProps } from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: AvatarProps) {
  const { user } = useRealmApp();
  const { displayName = '', picture = '' } = user;
  return (
    <Avatar
      src={picture}
      alt={displayName}
      color={picture ? 'default' : createAvatar(displayName ? displayName : '').color}
      {...other}
    >
      {createAvatar(displayName || 'Unknown').name}
    </Avatar>
  );
}
