import { useWatch } from '../useWatch';
import { WatchInput } from '../../hooks/watch/watchMany';
import { stringifyObjectIds } from '../../utils';

export default function watchOne({
  callerRef,
  collectionName,
  config: { setter, injector = (a: any) => a, cacheInvalidator = () => null, throttle },
}: WatchInput) {
  return useWatch(
    callerRef,
    collectionName,
    'watchOne',
    {
      onInsert: (change) => {
        const injected = injector(stringifyObjectIds(change.fullDocument));
        cacheInvalidator(change.fullDocument);
        setter(() => {
          return injected;
        });
      },
      onUpdate: (change) => {
        const injected = injector(stringifyObjectIds(change.fullDocument));
        cacheInvalidator(injected);
        setter(() => {
          return injected;
        });
      },
      onReplace: (change) => {
        const injected = injector(stringifyObjectIds(change.fullDocument));
        cacheInvalidator(change.fullDocument);
        setter(() => {
          return injected;
        });
      },
      onDelete: (change) => {
        cacheInvalidator(change.fullDocument);
        setter(() => {
          return null;
        });
      },
    },
    throttle
  );
}
