import { createContext, useContext } from 'react';

interface NavContextInterface {
  navConfig: any;
  PATH_LOGGED_IN: any;
  PATH_GUEST: { [key: string]: string };
}
// ----------------------------------------------------------------------
export const NavConfigContext = createContext<NavContextInterface>({} as NavContextInterface);

export const useNavConfigContext = () => {
  const context = useContext(NavConfigContext);
  if (context == null) {
    throw new Error('useNavConfigContext() called outside of a Provider?');
  }
  return context;
};
