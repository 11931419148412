import { UserTypes } from '@mymeeinc/types/user';
import {
  coach_AdminReadFields,
  coach_CoachReadFields,
  coach_CoachWriteInputFields,
  coach_CoachWriteSetFields,
  coach_MemberReadFields,
  coach_MemberWriteInputFields,
  coach_MemberWriteSetFields,
} from '../rules/coach';

export default {
  [UserTypes.ADMIN]: {
    queryWithoutId: {
      queryInput: '',
      queryName: 'adminCustomDatum',
      readFields: [...coach_AdminReadFields],
      outputField: 'adminCustomDatum',
    },
    queryWithId: {
      queryInput: '($admin_id: String)',
      queryName: 'adminCustomDatum(query: { admin_id: $admin_id })',
      readFields: [...coach_AdminReadFields],
      outputField: 'adminCustomDatum',
    },
  },
  [UserTypes.COACH]: {
    queryWithoutId: {
      queryInput: '',
      queryName: 'coachCustomDatum',
      readFields: [...coach_CoachReadFields],
      outputField: 'coachCustomDatum',
    },
    queryWithId: {
      queryInput: '($coach_id: String)',
      queryName: 'coachCustomDatum(query: { coach_id: $coach_id })',
      readFields: [...coach_CoachReadFields],
      outputField: 'coachCustomDatum',
    },
    editMutation: {
      name: 'updateOneCoachCustomDatum',
      setFields: [...coach_CoachWriteSetFields],
      inputFields: [...coach_CoachWriteInputFields],
      readFields: [...coach_CoachReadFields],
      outputField: 'coachCustomDatum',
      query: `{ coach_id: $coach_id }`,
    },
  },
  [UserTypes.MEMBER]: {
    queryWithoutId: {
      queryInput: '',
      queryName: 'memberCustomDatum',
      readFields: [...coach_MemberReadFields],
      outputField: 'memberCustomDatum',
    },
    queryWithId: {
      queryInput: '($member_id: String)',
      queryName: 'memberCustomDatum(query: { member_id: $member_id })',
      readFields: [...coach_MemberReadFields],
      outputField: 'memberCustomDatum',
    },
    editMutation: {
      name: 'updateOneMemberCustomDatum',
      setFields: [...coach_MemberWriteSetFields],
      inputFields: [...coach_MemberWriteInputFields],
      readFields: [...coach_MemberReadFields],
      outputField: 'memberCustomDatum',
      query: `{ member_id: $member_id }`,
    },
  },
};
