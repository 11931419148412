import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as Realm from 'realm-web';
import { Credentials } from 'realm-web';
import useSettings from 'minimal-ui/hooks/useSettings';
import {
  UserCustomData,
  UserCustomDataUnion,
  UserRealmContextData,
  UserTypes,
} from '@mymeeinc/types/user';
import { EJSON } from 'bson';
import { CustomData } from '@mymeeinc/types/customData';
import { useSmartFetch } from '../hooks/useSmartFetch';
import _ from 'lodash';
import uuidv4 from 'minimal-ui/utils/uuidv4';
import { capitalCase } from 'change-case';

type ErrorCallBack = (e: any) => any;

export interface RealmApp {
  app: Realm.App;
  currentUser: Realm.User | null;
  logIn: (cred: Realm.Credentials, errCall: ErrorCallBack) => Promise<void>;
  logInWithGoogle: (errCall?: ErrorCallBack) => Promise<void>;
  logOut: () => Promise<void>;
  // userLoading: boolean;
}

function createRealmApp(id: string, baseUrl: string) {
  return new Realm.App({ id, baseUrl });
}

export interface RealmAppContextInterface {
  realmApp: Realm.App;
  user: UserRealmContextData;
  userType: UserTypes;
  isLoading: boolean;
  loadingMapState: { [key: string]: boolean };
  logInWithGoogle: (errCall?: ErrorCallBack) => Promise<void>;
  logIn: (cred: Realm.Credentials, errCall: ErrorCallBack) => Promise<void>;
  logOut: () => Promise<void>;
  dataSourceName: string;
  baseUrl: string;
  refreshCustomData: () => void;
  setIsLoading: (identifier: string) => (isLoading: boolean) => void;
  customDataCollectionName?: string;
}

export const getUserIdFieldName = (userType: UserTypes) => {
  if (userType === UserTypes.MEMBER) {
    return 'member_id';
  } else if (userType === UserTypes.COACH) {
    return 'coach_id';
  } else if (userType === UserTypes.ADMIN) {
    return 'admin_id';
  }
  throw new Error('unknown userType: ' + userType);
};

export const getUserIdFromCustomData = (customData: UserCustomDataUnion): string => {
  if (customData.__typename === CustomData.MEMBER) {
    return customData.member_id;
  } else if (customData.__typename === CustomData.COACH) {
    return customData.coach_id;
  } else if (customData.__typename === CustomData.ADMIN) {
    return customData.admin_id;
  }
  throw new Error('unknown userType, check RealmApp getUserIdFromCustomData');
};
export const getUserTypeFromCustomData = (customData: UserCustomDataUnion): UserTypes => {
  if (customData.__typename === CustomData.MEMBER) {
    return UserTypes.MEMBER;
  } else if (customData.__typename === CustomData.COACH) {
    return UserTypes.COACH;
  } else if (customData.__typename === CustomData.ADMIN) {
    return UserTypes.ADMIN;
  }
  throw new Error('unknown userType, check RealmApp getUserTypeFromCustomData');
};

export const getUserId = (userType: UserTypes, customData: UserCustomDataUnion) => {
  if (userType === UserTypes.MEMBER) {
    return customData.member_id;
  } else if (userType === UserTypes.COACH) {
    return customData.coach_id;
  } else if (userType === UserTypes.ADMIN) {
    return customData.admin_id;
  }
  throw new Error('unknown userType, check RealmApp getId');
};

export const userDataFromCustomDataRow = (
  customData: UserCustomDataUnion
): UserRealmContextData => {
  customData = EJSON.deserialize(customData as any) as UserCustomDataUnion;
  const { first_name, last_name, email } = customData;
  const displayName = first_name || last_name ? [first_name, last_name].join(' ') : email;
  return {
    ...customData,
    user_id: getUserIdFromCustomData(customData),
    displayName,
  };
};
export const customData2UserData = (
  userType: UserTypes,
  customData: UserCustomData | null
): UserRealmContextData | null => {
  if (!customData) {
    return null;
  }
  try {
    customData = EJSON.deserialize(customData as any) as UserCustomDataUnion;
    const { first_name, last_name, email } = customData;
    const displayName = first_name || last_name ? [first_name, last_name].join(' ') : email;
    return {
      ...customData,
      user_id: getUserId(userType, customData as UserCustomDataUnion),
      displayName,
    } as UserRealmContextData;
  } catch (e) {
    console.error('EJSON.deserialize failed.');
    console.error(e);
    return null;
  }
};
let loadingTimeout: any;
const loadingMap: { [key: string]: boolean } = {};
type RealmConfiguration = {
  appId: string;
  baseUrl: string;
  dataSourceName: string;
  customDataCollectionName?: string;
};
export function RealmAppProvider({
  children,
  dev,
  staging,
  prod,
}: {
  children: JSX.Element;
  dev: RealmConfiguration;
  staging: RealmConfiguration;
  prod: RealmConfiguration;
}) {
  const isStaging = import.meta.env.MODE === 'staging';
  const isProd = import.meta.env.MODE === 'production';

  const { appId, baseUrl, dataSourceName, customDataCollectionName } = isProd
    ? prod
    : isStaging
    ? staging
    : dev;
  const [realmApp, setRealmApp] = useState(createRealmApp(appId, ''));
  const [loadingMapState, setLoadingMapState] = useState<{ [key: string]: boolean }>({});
  const { appUserType } = useSettings();
  const [user, _setUser] = useState<UserRealmContextData | null>(null);
  const [isLoading, _setIsLoading] = useState(!!realmApp?.currentUser);
  const setUser = (data: UserCustomData | null) => _setUser(customData2UserData(appUserType, data));
  useEffect(() => {
    setRealmApp(createRealmApp(appId, baseUrl));
  }, [appId]);
  const { invalidateCache } = useSmartFetch();

  const setIsLoading = (id: string) => (_isLoading: boolean) => {
    const before = Object.entries(loadingMap).length;
    if (_isLoading) {
      loadingMap[id] = _isLoading;
    } else {
      delete loadingMap[id];
    }

    const startLoading = () => {
      console.warn('START LOADING');
      // setInit(true);
      _setIsLoading(true);
    };

    const after = Object.entries(loadingMap).length;

    // console.log({
    //   before,
    //   after,
    //   objects: Object.keys(loadingMap),
    // });
    setLoadingMapState({ ...loadingMap });

    if (before === 0 && after > 0) {
      if (loadingTimeout) {
        console.warn('STOP LOADING Canceled. as new start arrived....');
        clearTimeout(loadingTimeout);
        loadingTimeout = null;
      } else {
        startLoading();
      }
    } else if (before > 0 && after === 0) {
      // clearTimeout(loadingTimeout);
      loadingTimeout = setTimeout(() => {
        console.warn('STOP LOADING');
        _setIsLoading(false);
      }, 200);
    }
  };
  const refreshCustomData = _.debounce(
    () => {
      const setLoading = setIsLoading(['RealmApp', 'refreshCustomData' + uuidv4()].join('|'));
      setLoading(true);
      realmApp.currentUser
        ?.refreshCustomData()
        .then((refreshData) => {
          console.error('ACCESS TOKEN REFRESHED');
          console.log({ refreshData });
          invalidateCache();
          setUser(refreshData as UserCustomDataUnion);
          setLoading(false);
        })
        .catch((e: any) => {
          console.error(e);
          setLoading(false);
          location.reload();
        });
      console.log('REFRESHING CUSTOM DATA....');
    },
    3000,
    {
      leading: true,
      trailing: false,
    }
  );

  useEffect(() => {
    if (realmApp?.currentUser) {
      const interval = setInterval(refreshCustomData, 1700000);
      console.warn('refresh requested from RealmApp');
      console.warn({ realmApp });
      refreshCustomData();
      return () => clearInterval(interval);
    }
  }, [realmApp?.id]);

  const logIn = useCallback(
    async (credentials: Credentials) => {
      try {
        await realmApp.logIn(credentials);
        setUser(realmApp.currentUser?.customData as UserCustomDataUnion);
      } catch (e: any) {
        console.log({ e });
        throw new Error(capitalCase(e.error));
      }
    },
    [realmApp]
  );
  const logInWithGoogle = useCallback(async (): Promise<void> => {
    // The redirect URI should be on the same domain as this app and
    // specified in the auth provider configuration.
    const hostURL =
      location.hostname === 'localhost'
        ? import.meta.env.VITE_LOCALHOST_URL
        : import.meta.env.VITE_HOST_URL;
    const credentials = Realm.Credentials.google(hostURL + '/guest/redirect');
    // Calling logIn() opens a Google authentication screen in a new window.
    realmApp
      .logIn(credentials)
      .then((user) => {
        // The logIn() promise will not resolve until you call `handleAuthRedirect()`
        // from the new window after the user has successfully authenticated.
        console.log(`Logged in with id: ${user.id}`);
        console.log({ user, cd: user?.customData, profile: user.profile });
        setUser(user?.customData as UserCustomDataUnion);
      })
      .catch((err) => console.error(err));
  }, [realmApp]);
  // Wrap the current user's logOut function to remove the logged out user from state
  const logOut = useCallback(async () => {
    if (realmApp.currentUser) {
      try {
        await realmApp.removeUser(realmApp.currentUser);
      } catch (e) {
        console.error(e);
      }
      await realmApp.currentUser?.logOut();
      setUser(null);
      location.reload();
    }
  }, [realmApp]);

  // Override the App's currentUser & logIn properties + include the app-level logout function
  const realmAppContext: RealmAppContextInterface = useMemo(() => {
    return {
      realmApp,
      user: user as UserRealmContextData,
      userType: appUserType,
      isLoading,
      loadingMapState,
      logIn,
      logInWithGoogle,
      logOut,
      dataSourceName,
      baseUrl,
      customDataCollectionName,
      refreshCustomData,
      setIsLoading,
    };
  }, [
    realmApp,
    appUserType,
    isLoading,
    loadingMapState,
    user,
    logIn,
    logInWithGoogle,
    logOut,
    dataSourceName,
    baseUrl,
    customDataCollectionName,
    refreshCustomData,
    setIsLoading,
  ]);

  return <RealmAppContext.Provider value={realmAppContext}>{children}</RealmAppContext.Provider>;
}

const RealmAppContext = createContext<RealmAppContextInterface>({} as RealmAppContextInterface);

export function useRealmApp(): RealmAppContextInterface {
  const realmApp = useContext(RealmAppContext);
  if (!realmApp) {
    throw new Error(
      `No Realm App found. Make sure to call useRealmApp() inside of a <RealmAppProvider />.`
    );
  }

  return realmApp;
}
