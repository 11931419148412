import { UserTypes } from '@mymeeinc/types/user';
import {
  member_CoachReadFields,
  member_AdminReadFields,
  member_MemberReadFields,
  member_MemberWriteInputFields,
  member_MemberWriteSetFields,
} from '../rules/member';

export default {
  [UserTypes.ADMIN]: {
    queryWithoutId: {
      queryInput: '',
      queryName: 'adminCustomDatum',
      readFields: [...member_AdminReadFields],
      outputField: 'adminCustomDatum',
    },
    queryWithId: {
      queryInput: '($admin_id: String)',
      queryName: 'adminCustomDatum(query: { admin_id: $admin_id })',
      readFields: [...member_AdminReadFields],
      outputField: 'adminCustomDatum',
    },
  },
  [UserTypes.COACH]: {
    queryWithoutId: {
      queryInput: '',
      queryName: 'coachCustomDatum',
      readFields: [...member_CoachReadFields],
      outputField: 'coachCustomDatum',
    },
    queryWithId: {
      queryInput: '($coach_id: String)',
      queryName: 'coachCustomDatum(query: { coach_id: $coach_id })',
      readFields: [...member_CoachReadFields],
      outputField: 'coachCustomDatum',
    },
  },
  [UserTypes.MEMBER]: {
    queryWithoutId: {
      queryInput: '',
      queryName: 'memberCustomDatum',
      readFields: [...member_MemberReadFields],
      outputField: 'memberCustomDatum',
    },
    queryWithId: {
      queryInput: '($member_id: String)',
      queryName: 'memberCustomDatum(query: { member_id: $member_id })',
      readFields: [...member_MemberReadFields],
      outputField: 'memberCustomDatum',
    },
    editMutation: {
      name: 'updateOneMemberCustomDatum',
      setFields: [...member_MemberWriteSetFields],
      inputFields: [...member_MemberWriteInputFields],
      readFields: [...member_MemberReadFields],
      outputField: 'memberCustomDatum',
      query: `{ member_id: $member_id }`,
    },
  },
};
