import { QueryByProjects } from './types';
import { Projects } from '@mymeeinc/types/config';
import admin from './admin';
import coach from './coach';
import member from './member';

export default {
  [Projects.ADMIN]: admin,
  [Projects.COACH]: coach,
  [Projects.MEMBER]: member,
} as QueryByProjects;
