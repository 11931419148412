import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// hooks
import { useRealmApp } from 'shared-web/components/RealmApp';
import LoadingScreen from 'minimal-ui/components/LoadingScreen';

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { user, isLoading } = useRealmApp();
  // const { pathname } = useLocation();
  // const [requestedLocation] = useState<string | null>(null);
  // console.log({ user, pathname, requestedLocation });

  if (user) {
    return <Navigate to="/" />;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
}
