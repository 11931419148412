export enum Projects {
  ADMIN = 'admin-webapp',
  COACH = 'coach-webapp',
  MEMBER = 'member-webapp',
}
export enum Genders {
  Unknown = '',
  Male = 'male',
  Female = 'female',
}
