import { combineReducers } from 'redux';
// slices
import mailReducer from './slices/mail';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';

// ----------------------------------------------------------------------

const rootReducer = combineReducers({
  mail: mailReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
});

export { rootReducer };
