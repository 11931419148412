import { UserTypes } from '@mymeeinc/types/user';
import {
  admin_AdminReadFields,
  admin_AdminWriteInputFields,
  admin_AdminWriteSetFields,
  admin_CoachReadFields,
  admin_CoachWriteInputFields,
  admin_CoachWriteSetFields,
  admin_MemberReadFields,
  admin_MemberWriteInputFields,
  admin_MemberWriteSetFields,
} from '../rules/admin';

export default {
  [UserTypes.ADMIN]: {
    queryWithoutId: {
      queryInput: '',
      queryName: 'adminCustomDatum',
      readFields: [...admin_AdminReadFields],
      outputField: 'adminCustomDatum',
    },
    queryWithId: {
      queryInput: '($admin_id: String)',
      queryName: 'adminCustomDatum(query: { admin_id: $admin_id })',
      readFields: [...admin_AdminReadFields],
      outputField: 'adminCustomDatum',
    },
    editMutation: {
      name: 'updateOneAdminCustomDatum',
      setFields: [...admin_AdminWriteSetFields],
      inputFields: [...admin_AdminWriteInputFields],
      readFields: [...admin_AdminReadFields],
      outputField: 'adminCustomDatum',
      query: `{ admin_id: $admin_id }`,
    },
  },
  [UserTypes.COACH]: {
    queryWithoutId: {
      queryInput: '',
      queryName: 'coachCustomDatum',
      readFields: [...admin_CoachReadFields],
      outputField: 'coachCustomDatum',
    },
    queryWithId: {
      queryInput: '($coach_id: String)',
      queryName: 'coachCustomDatum(query: { coach_id: $coach_id })',
      readFields: [...admin_CoachReadFields],
      outputField: 'coachCustomDatum',
    },
    editMutation: {
      name: 'updateOneCoachCustomDatum',
      setFields: [...admin_CoachWriteSetFields],
      inputFields: [...admin_CoachWriteInputFields],
      readFields: [...admin_CoachReadFields],
      outputField: 'coachCustomDatum',
      query: `{ coach_id: $coach_id }`,
    },
  },
  [UserTypes.MEMBER]: {
    queryWithoutId: {
      queryInput: '',
      queryName: 'memberCustomDatum',
      readFields: [...admin_MemberReadFields],
      outputField: 'memberCustomDatum',
    },
    queryWithId: {
      queryInput: '($member_id: String)',
      queryName: 'memberCustomDatum(query: { member_id: $member_id })',
      readFields: [...admin_MemberReadFields],
      outputField: 'memberCustomDatum',
    },
    editMutation: {
      name: 'updateOneMemberCustomDatum',
      setFields: [...admin_MemberWriteSetFields],
      inputFields: [...admin_MemberWriteInputFields],
      readFields: [...admin_MemberReadFields],
      outputField: 'memberCustomDatum',
      query: `{ member_id: $member_id }`,
    },
  },
};
