import {
  CoachCuratedDayToDayTag,
  FoodTag,
  CoachCuratedSymptomTag,
  UserSuggestedSymptomTag,
  CoachCuratedDiagnosisTag,
  UserSuggestedDiagnosisTag,
} from './tags';
import { Genders } from './config';
import { ObjectId } from 'bson';
import { UserTypes } from './user';

export enum CustomData {
  ADMIN = 'AdminCustomDatum',
  COACH = 'CoachCustomDatum',
  MEMBER = 'MemberCustomDatum',
}
export const customDataToUserType = (customDataType?: CustomData): UserTypes => {
  switch (customDataType) {
    case CustomData.MEMBER:
      return UserTypes.MEMBER;
    case CustomData.COACH:
      return UserTypes.COACH;
    case CustomData.ADMIN:
      return UserTypes.ADMIN;
  }
  throw new Error('unknown customData type: ' + customDataType);
};
export const userTypeToCustomData = (userType: UserTypes): CustomData => {
  switch (userType) {
    case UserTypes.MEMBER:
      return CustomData.MEMBER;
    case UserTypes.COACH:
      return CustomData.COACH;
    case UserTypes.ADMIN:
      return CustomData.ADMIN;
  }
  throw new Error('unknown userType: ' + userType);
};

export type AdminCustomDatum = {
  _id: ObjectId;
  __typename?: CustomData;
  admin_id: string;
  is_enabled: boolean;
  first_name: string;
  last_name: string;
  email: string;
  picture?: string;
  phone_number?: string;
  address?: string;
  country?: string;
  state?: string;
  city?: string;
  zip_code?: string;
};

export type MemberCustomDatum = {
  _id: ObjectId;
  __typename?: CustomData;
  member_id: string;
  coach_id: string | null;
  is_enabled: boolean;
  first_name?: string;
  last_activity?: Date;
  last_name?: string;
  email: string;
  picture?: string;
  phone_number?: string;
  address?: string;
  country?: string;
  state?: string;
  city?: string;
  zip_code?: string;
  birthdate?: Date;
  gender?: Genders;
  has_diagnosed?: boolean;
  has_subscription?: boolean;
  nickname?: string;
  subscription_start_date?: Date;
  subscription_end_date?: Date;
  account_created_at?: Date;
  tags_day_to_day: CoachCuratedDayToDayTag[];
  tags_diagnosis: CoachCuratedDiagnosisTag[];
  tags_food: FoodTag[];
  tags_symptom: CoachCuratedSymptomTag[];
  tags_user_symptom: UserSuggestedSymptomTag[];
  tags_user_diagnosis: UserSuggestedDiagnosisTag[];
};

export type CoachCustomDatum = {
  _id: ObjectId;
  __typename?: CustomData;
  coach_id: string;
  is_enabled: boolean;
  first_name: string;
  last_name: string;
  email: string;
  picture?: string;
  phone_number?: string;
  address?: string;
  country?: string;
  state?: string;
  city?: string;
  zip_code?: string;
  birthdate?: Date;
  gender?: Genders;
  acuity_access_token?: string;
  assigned_member_count?: number;
};

export type AssignCoachFn = (coach_id: string, member_id: string) => Promise<void>;
