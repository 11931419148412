export enum Projects {
  ADMIN = 'admin-webapp',
  COACH = 'coach-webapp',
  MEMBER = 'member-webapp',
}
export enum Genders {
  Male = 'Male',
  Female = 'Female',
  '' = '',
}

export const MEMORY_CACHE_TIME = 1000 * 60 * 30;
